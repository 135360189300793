import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import BackImage from "./../assets/img/background.jpg";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import ChoosePlayers from "./ChoosePlayers";
import Game from "./Game";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  button: {
    width: 200,
    margin: "5px 0"
  },
  splitContainer: {
    justifyContent: "center"
  },
  back: {
    backgroundImage: "url(" + BackImage + ")",
    backgroundPosition: "center",
    backgroundSize: "cover"
  },
  card: {
    width: 320,
    height: 360,
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, .9)"
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%"
  },
  textField: {
    width: "100%"
  },
  playerName: {
    color: "rgba(255, 255, 255, 1)",
    fontSize: 72
  },
  changeTurn: {
    fontSize: 12,
    marginTop: 10,
    color: "rgba(255, 255, 255, 1)"
  },
  tableDetails: {
    fontSize: 40,
    marginBottom: 10,
    fontWeight: "bold",
    color: "#000"
  },
  table: {
    minWidth: "100%"
  },
  tableCell: {
    textAlign: "center",
    padding: "20px 0",
    fontSize: 40
  },
  ball: {
    width: 70,
    height: 70,
    backgroundColor: "red",
    display: "block",
    textAlign: "center",
    lineHeight: "70px",
    color: "#fff",
    borderRadius: 70,
    float: "left",
    margin: 10,
    fontSize: 35,
    fontWeight: "bold"
  }
});

class Root extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messageSnack: "",
      openSnack: false,
      selectedPlayer1: { type: "user", name: "Maco" },
      selectedPlayer2: { type: "guest" },
      gameStarted: false
    };
    localStorage.setItem("authToken1", "");
    localStorage.setItem("authToken2", "");
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  openSnack = message => {
    this.setState({ openSnack: true, messageSnack: message });
  };

  closeSnack = () => {
    this.setState({ openSnack: false });
  };

  selectPlayer = (playerNumber, playerInfo) => {
    if (playerNumber === 1) {
      this.setState({ selectedPlayer1: playerInfo.user });
    } else {
      this.setState({ selectedPlayer2: playerInfo.user });
    }
    if (playerInfo.token !== "guest" && playerInfo.token !== "train" && playerInfo.token !== "") {
      localStorage.setItem("authToken" + playerNumber, "JWT " + playerInfo.token);
    } else {
      localStorage.setItem("authToken" + playerNumber, playerInfo.token);
    }
  };

  startGame = () => {
    if (this.state.selectedPlayer1 && this.state.selectedPlayer2) {
      this.setState({ gameStarted: true });
    }
  };
  endGame = () => {
    this.setState({ gameStarted: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <div style={{ backgroundColor: "#eeeeee", height: "100vh", width: "100vw" }}>
        {this.state.gameStarted ? (
          <Game
            selectedPlayer1={this.state.selectedPlayer1}
            selectedPlayer2={this.state.selectedPlayer2}
            classes={classes}
            openSnack={this.openSnack}
            endGame={this.endGame}
          />
        ) : (
          <ChoosePlayers
            selectedPlayer1={this.state.selectedPlayer1}
            selectedPlayer2={this.state.selectedPlayer2}
            selectPlayer={this.selectPlayer}
            classes={classes}
            openSnack={this.openSnack}
            startGame={this.startGame}
          />
        )}

        <Snackbar
          open={this.state.openSnack}
          onClose={this.closeSnack}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.messageSnack}</span>}
        />
      </div>
    );
  }
}

Root.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Root);
