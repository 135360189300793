import React from "react";
import axios from "axios";
import Button from "@material-ui/core/Button/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { serverUrl } from "../constants";
let syncTimer;

axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

function setTimer(value) {
  let time;
  let seconds = value;

  time = `${("0" + Math.trunc(seconds / 60)).slice(-2)}:${("0" + (seconds - Math.trunc(seconds / 60) * 60)).slice(-2)}`;

  return time;
}

let parseJwt = token => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

class Game extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      player1: "",
      player2: "",
      timer: {
        table: 0,
        frame: 0
      },
      turn: "",
      foul: false,
      foulPoints: 0,
      freeBall: false,
      ballsCount: {
        red: 15,
        yellow: 1,
        green: 1,
        brown: 1,
        blue: 1,
        pink: 1,
        black: 1
      },
      colourBall: false,
      pointsRemaining: 155,
      firstPlayer: {
        frames: 0,
        break: 0,
        points: 0,
        maxBreak: 0,
        lastBreak: 0
      },
      secondPlayer: {
        frames: 0,
        break: 0,
        points: 0,
        maxBreak: 0,
        lastBreak: 0
      },
      style: {
        player1Turn: "rgba(255, 0, 0, 1)",
        player2Turn: "rgba(255, 0, 0, 1)",
        ballOpacity: 1,
        redBall: "block"
      },
      gameId: false,
      table: localStorage.getItem("table")
    };
  }

  componentDidMount() {
    this.completePlayersDetails();
    this.startTableTime();
    this.startFrameTime();
    this.setTurn("player1");
    axios.defaults.headers.common = { Authorization: localStorage.getItem("authToken1") };
    console.log(axios.defaults.headers.common);
    let self = this;
    syncTimer = setInterval(() => {
      console.log(this.state);
      axios
        .post(serverUrl + "/api/secure/game", { data: self.state })
        .then(response => {
          self.setState({ gameId: response.data.data.gameId });
          console.log(response.data.data.gameId);
        })
        .catch(err => {
          console.error(err.response);
        });
    }, 3000);
  }

  componentWillUnmount() {
    clearInterval(syncTimer);
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(this.props.selectedPlayer1);
    // console.log(this.props.selectedPlayer2);
  }

  completePlayersDetails = () => {
    if (this.props.selectedPlayer1.type === "user") {
      this.setState({ player1: this.props.selectedPlayer1.name });
      this.setState({ player1Id: parseJwt(localStorage.getItem("authToken1"))._id });
    }
    if (this.props.selectedPlayer2.type === "user") {
      this.setState({ player2: this.props.selectedPlayer2.name });
      this.setState({ player2Id: parseJwt(localStorage.getItem("authToken2"))._id });
    } else {
      this.setState({ player2: this.props.selectedPlayer2.type });
      this.setState({ player2Id: this.props.selectedPlayer2.type });
    }
  };

  startTableTime() {
    setInterval(() => {
      this.setState(prevState => ({
        timer: { ...prevState.timer, table: this.state.timer.table + 1 }
      }));
    }, 1000);
  }

  startFrameTime() {
    setInterval(() => {
      if (this.state.turn !== "")
        this.setState(prevState => ({
          timer: { ...prevState.timer, frame: this.state.timer.frame + 1 }
        }));
    }, 1000);
  }

  async setTurn(player) {
    await this.updatePoints();
    await this.updateMaxBreak();

    let colourBall;
    let firstPlayer;
    let secondPlayer;
    let style;

    [firstPlayer, secondPlayer, style] = await Promise.all([
      Object.assign({}, this.state.firstPlayer),
      Object.assign({}, this.state.secondPlayer),
      Object.assign({}, this.state.style)
    ]);

    if (this.state.colourBall === true || this.state.colourBall === false) colourBall = false;
    else colourBall = this.state.colourBall;

    if (player === "player1") {
      secondPlayer.lastBreak = secondPlayer.break;
      style.player1Turn = "rgba(40, 164, 40, 1)";
      style.player2Turn = "rgba(255, 0, 0, 1)";
      style.ballOpacity = 1;

      if (this.state.foul) {
        firstPlayer.points = this.state.firstPlayer.points + this.state.foulPoints;
        secondPlayer.break = 0;
      }
    } else if (player === "player2") {
      firstPlayer.lastBreak = firstPlayer.break;
      style.player1Turn = "rgba(255, 0, 0, 1)";
      style.player2Turn = "rgba(40, 164, 40, 1)";
      style.ballOpacity = 1;

      if (this.state.foul) {
        secondPlayer.points = secondPlayer.points + this.state.foulPoints;
        firstPlayer.break = 0;
      }
    }
    this.setState({
      turn: player,
      foul: false,
      foulPoints: 0,
      colourBall: colourBall,
      firstPlayer: firstPlayer,
      secondPlayer: secondPlayer,
      style: style
    });
  }

  async ballClick(type, points) {
    if (this.state.turn === "") {
      alert("No player selected");
      return;
    }

    if (this.state.foul) {
      let foulPoints;

      if (points <= 4) foulPoints = 4;
      else foulPoints = points;

      this.setState({
        foulPoints: foulPoints
      });

      return;
    }

    if (this.state.freeBall) {
      let firstPlayer;
      let secondPlayer;
      let style;

      [firstPlayer, secondPlayer, style] = await Promise.all([
        Object.assign({}, this.state.firstPlayer),
        Object.assign({}, this.state.secondPlayer),
        Object.assign({}, this.state.style)
      ]);

      style.redBall = "block";

      if (this.state.turn === "player1") {
        firstPlayer.points += 1 + points;
      } else if (this.state.turn === "player2") {
        secondPlayer.points += 1 + points;
      }

      this.setState({
        freeBall: false,
        firstPlayer: firstPlayer,
        secondPlayer: secondPlayer,
        style: style
      });

      return;
    }

    this.updateBreak(type, points);
  }

  async updateBreak(type, points) {
    let colourBall = this.state.colourBall;
    let pointsRemaining = this.state.pointsRemaining;
    let firstPlayer;
    let secondPlayer;

    [firstPlayer, secondPlayer] = await Promise.all([
      Object.assign({}, this.state.firstPlayer),
      Object.assign({}, this.state.secondPlayer)
    ]);

    if (type === "red") {
      await this.removeBall(type);

      colourBall = true;
      pointsRemaining = this.state.ballsCount.red * 8 + 27;

      if (this.state.ballsCount.red === 0) {
        colourBall = 2;
      }

      if (this.state.turn === "player1") {
        firstPlayer.break += points;
      } else if (this.state.turn === "player2") {
        secondPlayer.break += points;
      }
    } else {
      if (this.state.colourBall) {
        if (this.state.ballsCount.red !== 0) {
          colourBall = false;
          pointsRemaining -= points;

          if (this.state.turn === "player1") {
            firstPlayer.break += points;
          } else if (this.state.turn === "player2") {
            secondPlayer.break += points;
          }
        } else {
          if (points === this.state.colourBall) {
            await this.removeBall(type);

            colourBall = this.state.colourBall + 1;
            pointsRemaining -= points;

            if (this.state.turn === "player1") {
              firstPlayer.break += points;
            } else if (this.state.turn === "player2") {
              secondPlayer.break += points;
            }

            if (type === "black") {
              firstPlayer.points += firstPlayer.break;
              secondPlayer.points += secondPlayer.break;

              if (firstPlayer.break > firstPlayer.maxBreak) {
                firstPlayer.maxBreak = firstPlayer.break;
              }

              if (secondPlayer.break > secondPlayer.maxBreak) {
                secondPlayer.maxBreak = secondPlayer.break;
              }
            }
          } else {
            alert("FOUL");
          }
        }
      } else {
        alert("FOUL");
      }
    }

    this.setState({
      colourBall: colourBall,
      pointsRemaining: pointsRemaining,
      firstPlayer: firstPlayer,
      secondPlayer: secondPlayer
    });
  }

  updatePoints() {
    this.setState(prevState => ({
      firstPlayer: {
        ...prevState.firstPlayer,
        points: this.state.firstPlayer.points + this.state.firstPlayer.break
      },
      secondPlayer: {
        ...prevState.secondPlayer,
        points: this.state.secondPlayer.points + this.state.secondPlayer.break
      }
    }));
  }

  async updateMaxBreak() {
    let firstPlayer;
    let secondPlayer;

    [firstPlayer, secondPlayer] = await Promise.all([
      Object.assign({}, this.state.firstPlayer),
      Object.assign({}, this.state.secondPlayer)
    ]);

    if (this.state.turn === "player1") {
      if (this.state.firstPlayer.break > this.state.firstPlayer.maxBreak) {
        firstPlayer.maxBreak = this.state.firstPlayer.break;
      }
    } else if (this.state.turn === "player2") {
      if (this.state.secondPlayer.break > this.state.secondPlayer.maxBreak) {
        secondPlayer.maxBreak = this.state.secondPlayer.break;
      }
    }

    this.setState({
      firstPlayer: firstPlayer,
      secondPlayer: secondPlayer
    });
  }

  async removeBall(type) {
    let ballsCount;

    ballsCount = await Promise.resolve(Object.assign({}, this.state.ballsCount));

    if (type === "red") ballsCount.red = this.state.ballsCount.red - 1;
    if (type === "yellow") ballsCount.yellow = 0;
    if (type === "green") ballsCount.green = 0;
    if (type === "brown") ballsCount.brown = 0;
    if (type === "blue") ballsCount.blue = 0;
    if (type === "pink") ballsCount.pink = 0;
    if (type === "black") ballsCount.black = 0;

    this.setState({
      ballsCount: ballsCount
    });
  }

  handleFoul() {
    alert("Select ball");

    this.setState(prevState => ({
      foul: true,
      style: { ...prevState.style, ballOpacity: 0.5 }
    }));
  }

  handleFreeBall() {
    alert("FREE BALL. SELECT BALL");

    this.setState(prevState => ({
      freeBall: true,
      style: { ...prevState.style, redBall: "none" }
    }));
  }

  async handleResetPoints() {
    let firstPlayer;
    let secondPlayer;

    [firstPlayer, secondPlayer] = await Promise.all([
      Object.assign({}, this.state.firstPlayer),
      Object.assign({}, this.state.secondPlayer)
    ]);

    if (this.state.turn === "player1") {
      firstPlayer.points -= this.state.firstPlayer.lastBreak;
    } else if (this.state.turn === "player2") {
      secondPlayer.points -= this.state.secondPlayer.lastBreak;
    }

    this.setState({
      firstPlayer: firstPlayer,
      secondPlayer: secondPlayer
    });
  }

  async handleEndGame() {
    let firstPlayer;
    let secondPlayer;

    [firstPlayer, secondPlayer] = await Promise.all([
      Object.assign({}, this.state.firstPlayer),
      Object.assign({}, this.state.secondPlayer)
    ]);

    if (firstPlayer.points > secondPlayer.points) {
      firstPlayer.frames += 1;
    } else if (firstPlayer.points < secondPlayer.points) {
      secondPlayer.frames += 1;
    }

    firstPlayer.break = firstPlayer.points = firstPlayer.maxBreak = secondPlayer.break = secondPlayer.points = secondPlayer.maxBreak = 0;

    this.setState({
      timer: {
        table: this.state.timer.table,
        frame: 0
      },
      turn: "",
      foul: false,
      foulPoints: 0,
      freeBall: false,
      ballsCount: {
        red: 15,
        yellow: 1,
        green: 1,
        brown: 1,
        blue: 1,
        pink: 1,
        black: 1
      },
      colourBall: false,
      pointsRemaining: 155,
      firstPlayer: firstPlayer,
      secondPlayer: secondPlayer,
      style: {
        player1Turn: "rgba(255, 0, 0, 1)",
        player2Turn: "rgba(255, 0, 0, 1)",
        ballOpacity: 1,
        redBall: "block"
      },
      gameId: false,
      table: localStorage.getItem("table")
    });
  }

  render() {
    let { classes } = this.props;
    return (
      <div style={{ padding: 20 }}>
        <div className={classes.root}>
          <Grid container spacing={24}>
            <Grid item xs={4}>
              <Paper
                className={classes.paper}
                style={{ background: this.state.style.player1Turn }}
                onClick={() => {
                  this.setTurn("player1");
                }}
              >
                <div className={classes.playerName}>{this.state.player1}</div>
                <div className={classes.changeTurn}>click to set your turn</div>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paper}>
                <div className={classes.tableDetails}>TABLE 1</div>
                <div className={classes.tableDetails}>{setTimer(this.state.timer.table)}</div>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper
                className={classes.paper}
                style={{ background: this.state.style.player2Turn }}
                onClick={() => {
                  this.setTurn("player2");
                }}
              >
                <div className={classes.playerName}>{this.state.player2}</div>
                <div className={classes.changeTurn}>click to set your turn</div>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Paper className={classes.root}>
                <Table className={classes.table}>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.tableCell} style={{ width: "30%" }}>
                        {this.state.firstPlayer.frames}
                      </TableCell>
                      <TableCell className={classes.tableCell} style={{ width: "40%" }}>
                        Frames
                        <div className={classes.tableDetails} style={{ fontSize: "25px", fontWeight: "normal" }}>
                          Time: {setTimer(this.state.timer.frame)}
                        </div>
                      </TableCell>
                      <TableCell className={classes.tableCell} style={{ width: "30%" }}>
                        {this.state.secondPlayer.frames}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCell} style={{ width: "30%" }}>
                        {this.state.firstPlayer.break}
                      </TableCell>
                      <TableCell className={classes.tableCell} style={{ width: "40%" }}>
                        Break
                      </TableCell>
                      <TableCell className={classes.tableCell} style={{ width: "30%" }}>
                        {this.state.secondPlayer.break}
                      </TableCell>
                    </TableRow>
                    <TableRow style={{ background: "rgba(154, 229, 154, 1)" }}>
                      <TableCell
                        className={classes.tableCell}
                        style={{ width: "30%", fontSize: "72px", fontWeight: "bold" }}
                      >
                        {this.state.firstPlayer.points}
                      </TableCell>
                      <TableCell className={classes.tableCell} style={{ width: "40%" }}>
                        Points
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        style={{ width: "30%", fontSize: "72px", fontWeight: "bold" }}
                      >
                        {this.state.secondPlayer.points}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCell} style={{ width: "30%", color: "rgba(255, 0, 0, 1)" }}>
                        {this.state.firstPlayer.maxBreak}
                      </TableCell>
                      <TableCell className={classes.tableCell} style={{ width: "40%" }}>
                        Max break
                      </TableCell>
                      <TableCell className={classes.tableCell} style={{ width: "30%", color: "rgba(255, 0, 0, 1)" }}>
                        {this.state.secondPlayer.maxBreak}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCell} style={{ width: "30%", borderBottom: "none" }} />
                      <TableCell className={classes.tableCell} style={{ width: "40%", borderBottom: "none" }}>
                        Puncte ramase
                      </TableCell>
                      <TableCell className={classes.tableCell} style={{ width: "30%", borderBottom: "none" }} />
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCell} style={{ width: "30%" }} />
                      <TableCell className={classes.tableCell} style={{ width: "40%" }}>
                        {this.state.pointsRemaining}
                      </TableCell>
                      <TableCell className={classes.tableCell} style={{ width: "30%" }} />
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Paper className={classes.root} style={{ justifyContent: "center", display: "flex" }}>
                <span
                  className={classes.ball}
                  style={{ opacity: this.state.style.ballOpacity, display: this.state.style.redBall }}
                  onClick={() => {
                    this.ballClick("red", 1);
                  }}
                >
                  {this.state.ballsCount.red}
                </span>
                <span
                  className={classes.ball}
                  style={{
                    backgroundColor: "yellow",
                    color: "#000",
                    opacity: this.state.style.ballOpacity
                  }}
                  onClick={() => {
                    this.ballClick("yellow", 2);
                  }}
                >
                  {this.state.ballsCount.yellow}
                </span>
                <span
                  className={classes.ball}
                  style={{ backgroundColor: "green", opacity: this.state.style.ballOpacity }}
                  onClick={() => {
                    this.ballClick("green", 3);
                  }}
                >
                  {this.state.ballsCount.green}
                </span>
                <span
                  className={classes.ball}
                  style={{ backgroundColor: "brown", opacity: this.state.style.ballOpacity }}
                  onClick={() => {
                    this.ballClick("brown", 4);
                  }}
                >
                  {this.state.ballsCount.brown}
                </span>
                <span
                  className={classes.ball}
                  style={{ backgroundColor: "blue", opacity: this.state.style.ballOpacity }}
                  onClick={() => {
                    this.ballClick("blue", 5);
                  }}
                >
                  {this.state.ballsCount.blue}
                </span>
                <span
                  className={classes.ball}
                  style={{ backgroundColor: "rgba(255, 77, 166, 1)", opacity: this.state.style.ballOpacity }}
                  onClick={() => {
                    this.ballClick("pink", 6);
                  }}
                >
                  {this.state.ballsCount.pink}
                </span>
                <span
                  className={classes.ball}
                  style={{ backgroundColor: "black", opacity: this.state.style.ballOpacity }}
                  onClick={() => {
                    this.ballClick("black", 7);
                  }}
                >
                  {this.state.ballsCount.black}
                </span>
              </Paper>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={24}>
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="default"
              size="large"
              className={classes.button}
              style={{ width: "100%", height: 70 }}
              onClick={() => {
                this.handleFoul();
              }}
            >
              Fault
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="default"
              size="large"
              className={classes.button}
              style={{ width: "100%", height: 70 }}
              onClick={() => {
                this.handleFreeBall();
              }}
            >
              Free ball
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              className={classes.button}
              style={{ width: "100%", height: 70 }}
              onClick={() => {
                this.handleResetPoints();
              }}
            >
              Reset points
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              style={{ width: "100%", height: 70 }}
              onClick={() => {
                this.handleEndGame();
              }}
            >
              End game
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default Game;
