import React from "react";
import axios from "axios";
import { serverUrl } from "../constants";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";

import { parseJwt } from "../utils";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleSubmit = () => {
    let self = this;
    axios
      .post(serverUrl + "/api/auth/authenticate", {
        email: this.state.email,
        password: this.state.password
      })
      .then(function(response) {
        if (self.props.player === 1 && localStorage.getItem("authToken2")) {
          if (localStorage.getItem("authToken2").split(" ").length === 2) {
            let alreadySet = parseJwt(localStorage.getItem("authToken2").split(" ")[1]);
            let got = parseJwt(response.data.data.token);
            if (alreadySet._id === got._id) {
              return self.props.openSnack("Logged as player 2.");
            }
          }
        }
        if (self.props.player === 2 && localStorage.getItem("authToken1")) {
          if (localStorage.getItem("authToken1").split(" ").length === 2) {
            let alreadySet = parseJwt(localStorage.getItem("authToken1").split(" ")[1]);
            let got = parseJwt(response.data.data.token);
            if (alreadySet._id === got._id) {
              return self.props.openSnack("Logged as player 1.");
            }
          }
        }
        let userInfo = response.data.data;
        userInfo.user.type = "user";
        self.props.selectPlayer(self.props.player, response.data.data);
        self.props.openSnack("Logged in.");
      })
      .catch(function(error) {
        try {
          self.props.openSnack(error.response.data.data);
        } catch (e) {
          console.log(error);
          self.props.openSnack("Something went wrong.");
        }
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <h2 style={{ margin: 0 }}>Log in</h2>
        <form className={classes.container} noValidate>
          <TextField
            required
            id={"standard-required" + this.props.player}
            label="Email"
            className={classes.textField}
            onChange={this.handleChange("email")}
            margin="normal"
          />
          <TextField
            id={"standard-password-input" + this.props.player}
            required
            label="Password"
            className={classes.textField}
            type="password"
            onChange={this.handleChange("password")}
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            onClick={this.handleSubmit}
            style={{ width: "100%" }}
          >
            Sign in
          </Button>
        </form>
      </div>
    );
  }
}

export default Login;
