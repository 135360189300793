import React from "react";
import Grid from "@material-ui/core/Grid/Grid";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Login from "./Login";
import Button from "@material-ui/core/Button/Button";

class AlreadyLogged extends React.Component {
  render() {
    let { classes } = this.props;
    return (
      <div>
        {this.props.playerInfo.type === "user" ? (
          <div>
            <div>
              Logged in as <span style={{ color: "#3f51b5", fontWeight: "bold" }}>{this.props.playerInfo.name}</span>
            </div>
          </div>
        ) : this.props.playerInfo.type === "guest" ? (
          <div>
            Play as <span style={{ color: "#3f51b5", fontWeight: "bold" }}>GUEST</span>
          </div>
        ) : this.props.playerInfo.type === "train" ? (
          <div>
            <span style={{ color: "#3f51b5", fontWeight: "bold" }}>TRAINING</span>
          </div>
        ) : null}
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
          onClick={() => {
            this.props.selectPlayer(this.props.playerNumber, { user: "", token: "" });
          }}
          style={{ width: "100%", marginTop: 30 }}
        >
          Change player
        </Button>
      </div>
    );
  }
}

class ChoosePlayers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { classes } = this.props;
    return (
      <div className={classes.back}>
        <Grid container spacing={0} alignItems="center" justify="center" style={{ minHeight: "100vh" }}>
          <Grid item xs={6} align={"center"} className={classes.splitContainer}>
            <Card className={classes.card}>
              <CardContent>
                {this.props.selectedPlayer1 ? (
                  <AlreadyLogged
                    playerNumber={1}
                    playerInfo={this.props.selectedPlayer1}
                    classes={classes}
                    selectPlayer={this.props.selectPlayer}
                  />
                ) : (
                  <Login
                    classes={classes}
                    openSnack={this.props.openSnack}
                    player={1}
                    selectedPlayer={this.props.selectedPlayer1}
                    selectPlayer={this.props.selectPlayer}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item align={"center"} xs={6}>
            <Card className={classes.card}>
              <CardContent>
                <div>
                  {this.props.selectedPlayer2 ? (
                    <AlreadyLogged
                      playerNumber={2}
                      playerInfo={this.props.selectedPlayer2}
                      classes={classes}
                      selectPlayer={this.props.selectPlayer}
                    />
                  ) : (
                    <div>
                      <Login
                        classes={classes}
                        openSnack={this.props.openSnack}
                        player={2}
                        selectedPlayer={this.props.selectedPlayer2}
                        selectPlayer={this.props.selectPlayer}
                      />
                      <div>
                        <div>
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            size={"large"}
                            style={{ width: "100%" }}
                            onClick={() => {
                              this.props.selectPlayer(2, { user: { type: "guest" }, token: "guest" });
                            }}
                          >
                            Play as Guest
                          </Button>
                        </div>
                        <div>
                          <Button
                            variant="contained"
                            color="default"
                            className={classes.button}
                            size={"large"}
                            style={{ width: "100%" }}
                            onClick={() => {
                              this.props.selectPlayer(2, { user: { type: "train" }, token: "train" });
                            }}
                          >
                            Train
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {this.props.selectedPlayer1 && this.props.selectedPlayer2 ? (
          <Button
            variant="outlined"
            size={"large"}
            className={classes.button}
            onClick={this.props.startGame}
            style={{
              position: "absolute",
              bottom: 30,
              left: "50%",
              marginLeft: "-100px",
              borderColor: "#fff",
              color: "#fff"
            }}
          >
            Start game
          </Button>
        ) : null}
      </div>
    );
  }
}
export default ChoosePlayers;
